import Axios from 'axios';
import { Ref, computed, ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { ReachAnalysis2Api } from '@/api';
import {
  ReachAnalysisProject2CardConditionFromCostTypeEnum,
  ReachAnalysisProject2ConditionForm,
  ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum,
  ReachAnalysisProject2ConditionFormCmBuyingKindEnum,
  ReachAnalysisProject2ConditionFormCostTypeEnum,
  ReachAnalysisProject2TargetSettings,
  ReachAnalysisProject2TargetSettingsTargetTypeEnum
} from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { COMPANY_ROUTES, REACH_ANALYSIS_ROUTES, ROUTE_NAMES } from '@/router';

import { PeriodListType } from '@/common/period';
import { FormValueType } from '@/composables/reachanalysis/reachAnalysisForm';

interface Create {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  formValue: Ref<FormValueType | undefined>;
  projectName: Ref<string>;
  isSubMenuOpen: Ref<boolean>;
  createProject: (params: unknown) => Promise<void>;
  isCreatingProject: Ref<boolean>;
  goToList: () => Promise<void>;
  validProjectName: Ref<string | undefined>;
  isCreateButtonDisabled: Ref<boolean>;
}

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'エフェクティブリーチ' },
    {
      name: REACH_ANALYSIS_ROUTES.list,
      label: 'リーチ分析'
    }
  ],
  current: { label: '新規作成' }
};

export const useCreate = (): Create => {
  const router = useRouter();
  const route = useRoute();
  const companyId = route.params.companyId.toString();
  const breadcrumbs = ref(BREADCRUMBS);
  const formValue: Ref<FormValueType | undefined> = shallowRef();
  const projectName = ref('');
  const isSubMenuOpen = ref(false);

  const convertToCmType = (
    type: 'TIME' | 'SPOT' | undefined
  ): ReachAnalysisProject2ConditionFormCmBuyingKindEnum => {
    switch (type) {
      case 'TIME':
        return ReachAnalysisProject2ConditionFormCmBuyingKindEnum.Time;
      case 'SPOT':
        return ReachAnalysisProject2ConditionFormCmBuyingKindEnum.Spot;
      default:
        return ReachAnalysisProject2ConditionFormCmBuyingKindEnum.All;
    }
  };

  const convertToCostType = (
    costType: ReachAnalysisProject2CardConditionFromCostTypeEnum
  ): ReachAnalysisProject2ConditionFormCostTypeEnum => {
    switch (costType) {
      case ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost:
        return ReachAnalysisProject2ConditionFormCostTypeEnum.PlacementCost;
      case ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost:
        return ReachAnalysisProject2ConditionFormCostTypeEnum.PerCost;
      case ReachAnalysisProject2CardConditionFromCostTypeEnum.ReferMainPerCost:
        return ReachAnalysisProject2ConditionFormCostTypeEnum.ReferMainPerCost;
      default:
        return ReachAnalysisProject2ConditionFormCostTypeEnum.PlacementCost;
    }
  };

  const convertToTargetType = (
    name: string
  ): ReachAnalysisProject2TargetSettingsTargetTypeEnum => {
    switch (name) {
      case TARGET_NAME.individual:
      case TARGET_NAME.household:
      case TARGET_NAME.individualAndHousehold:
        return ReachAnalysisProject2TargetSettingsTargetTypeEnum.Individual;
      case TARGET_NAME.genderAge12Type:
        return ReachAnalysisProject2TargetSettingsTargetTypeEnum.BasicGa12;
      case TARGET_NAME.gender10Type:
        return ReachAnalysisProject2TargetSettingsTargetTypeEnum.BasicGa10S;
      case TARGET_NAME.customTarget:
        return ReachAnalysisProject2TargetSettingsTargetTypeEnum.CustomTarget;
      default:
        return ReachAnalysisProject2TargetSettingsTargetTypeEnum.Individual;
    }
  };

  const convertToPeriodModeEnum = (
    val: PeriodListType | undefined
  ): ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum => {
    if (!val) {
      return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.Manual;
    }
    switch (val?.key) {
      case 'lastQuarter':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.PreviousQuarter;
      case 'lastCools':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.PreviousSeason;
      case 'currentCools':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.CurrentSeason;
      case 'lastMonth':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.PreviousMonth;
      case 'fourWeeksAgo':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.Previous4Weeks;
      case 'lastWeek':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.PreviousWeek;
      case 'currentMonth':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.CurrentMonth;
      case 'lastTwentyEightDays':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.Last28Days;
      case 'lastFourteenDays':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.Last14Days;
      case 'custom':
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.Auto;
      default:
        return ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum.Manual;
    }
  };

  const _createProject = async () => {
    if (!formValue.value) return;
    const form = formValue.value;
    if (
      !form.areaValue.value ||
      !form.startDateStr.value ||
      !form.endDateStr.value ||
      !form.products.value
    ) {
      return;
    }

    let placementCost: number | undefined = undefined;
    let perCost: number | undefined = undefined;
    switch (form.costType.value) {
      case ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost:
        placementCost = form.costNum.value;
        break;
      case ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost:
        perCost = form.costNum.value;
        break;
      default:
        break;
    }
    const costType = form.costNum.value
      ? convertToCostType(form.costType.value)
      : undefined;

    const basicInfo: ReachAnalysisProject2ConditionForm = {
      projectName: projectName.value,
      areaCode: form.areaValue.value,
      calenderPeriodMode: convertToPeriodModeEnum(form.periodShortcut.value),
      startDate: form.startDateStr.value,
      endDate: form.endDateStr.value,
      cmSponsors: form.sponsorsIds.value,
      products: form.products.value,
      cmCreativeIds: form.selectedCmCreativeIds.value,
      cmBuyingKind: convertToCmType(form.cmTypeValue.value),
      numOfEffectiveContacts: form.numOfEffectiveContacts.value,
      placementCost: placementCost,
      perCost: perCost,
      costType: costType
    };

    const targetList =
      form.targetName.value === TARGET_NAME.customTarget
        ? form.customTargetId.value
        : form.basicTargetIds.value;
    const targets = targetList.map(v => ({
      targetId: v,
      targetName: form?.targetName.value
    }));

    const targetSetting: ReachAnalysisProject2TargetSettings = {
      targetType: convertToTargetType(form.targetName.value),
      targets: targets
    };

    const reachAnalysisProject2Form = {
      basicInfo,
      targetSetting
    };

    try {
      const res = await ReachAnalysis2Api.postCompaniesCompanyIdReachAnalysisProjects2(
        Number(companyId),
        reachAnalysisProject2Form
      );
      if (200 <= res.status && res.status < 300) {
        const projectId = res.data.basicInfo.projectId;
        await router.push({
          name: REACH_ANALYSIS_ROUTES.result,
          params: { ...route.params, projectId }
        });
        toast({
          title: '成功',
          message: 'プロジェクトの作成に成功しました',
          variant: 'success'
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの作成に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e);
      } else if (e.status === 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの作成に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの作成に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isCreatingProject, createProject] = useLoading(_createProject);

  const goToList = async () => {
    await router.push({
      name: REACH_ANALYSIS_ROUTES.list,
      params: { ...route.params }
    });
  };

  const validProjectName: Ref<string | undefined> = computed(() => {
    if (projectName.value.length > 64) {
      return VALIDATION_MESSAGE.max64;
    }
    if (projectName.value.length && !projectName.value.match(/\S/g)) {
      return VALIDATION_MESSAGE.invalidChar;
    }
    return undefined;
  });

  const isCreateButtonDisabled = computed(() => {
    const form = formValue.value;
    return !form ||
      !form.areaValue.value ||
      !form.cmProductsIds.value ||
      !form.startDateStr.value ||
      !form.endDateStr.value ||
      !projectName.value ||
      formValue.value?.isFormDisabled.value ||
      validProjectName.value ||
      isCreatingProject.value
      ? true
      : false;
  });

  return {
    breadcrumbs,
    formValue,
    projectName,
    isSubMenuOpen,
    createProject,
    isCreatingProject,
    goToList,
    validProjectName,
    isCreateButtonDisabled
  };
};
